<template>
  <v-toolbar :color="$vuetify.breakpoint.mdAndDown ? '#28243d' : 'transparent'" flat :height="68">
    <v-toolbar-title class="text-h6 font-weight-medium">
      <v-icon
        left
        :class="{ 'mr-3': pageStore.isFullScreen }"
        color="white"
        :large="pageStore.isFullScreen"
      >
        {{ pageStore.pageIcon }}
      </v-icon>
      <span class="white--text" :class="pageStore.isFullScreen ? 'text-h6' : 'text-body-1'">{{
        pageStore.pageTitle
      }}</span>
    </v-toolbar-title>

    <v-spacer></v-spacer>

    <div :style="$vuetify.breakpoint.mdAndDown ? 'zoom:0.85; margin-right:-30px' : ''">
      <v-card
        :rounded="$vuetify.breakpoint.mdAndDown ? 'l-pill' : 'pill'"
        :color="$vuetify.breakpoint.mdAndDown ? 'night' : 'chip'"
        flat
        v-if="pageStore.hasFullScreen || pageStore.hasDownload || pageStore.hasDateRange"
      >
        <v-card-title class="py-1 px-2">
          <v-spacer></v-spacer>
          <Fullscreen v-if="pageStore.hasFullScreen && !$vuetify.breakpoint.mdAndDown" />
          <Download v-if="pageStore.hasDownload" />
          <DateRange v-if="pageStore.hasDateRange" />
          <div v-if="pageStore.hasActions">
            <slot name="action"></slot>
          </div>
        </v-card-title>
      </v-card>
    </div>
  </v-toolbar>
</template>

<script>
// Pinia
import { usePageStore } from "@/stores/pageHeader";

// Components
import Download from "./Download.vue";
import DateRange from "./DateRange.vue";
import Fullscreen from "./Fullscreen.vue";

export default {
  components: {
    Download,
    DateRange,
    Fullscreen,
  },

  setup(props) {
    const pageStore = usePageStore();

    return {
      pageStore,
    };
  },
};
</script>
