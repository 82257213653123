<template>
  <v-btn fab rounded small plain @click="fullScreen">
    <v-icon>{{ !pageStore.isFullScreen ? icons.mdiFullscreen : icons.mdiFullscreenExit }}</v-icon>
  </v-btn>
</template>

<script>
import { onMounted, onUnmounted } from "vue-demi";

// Pinia
import { usePageStore } from "@/stores/pageHeader";

// Icons
import { mdiFullscreen, mdiFullscreenExit } from "@mdi/js";

export default {
  setup(props) {
    const pageStore = usePageStore();
    const handleFullscreen = () => {
      pageStore.isFullScreen = !pageStore.isFullScreen;
    };

    const fullScreen = () => {
      if (document.fullscreen) {
        document.exitFullscreen();
      } else if (document.webkitFullscreenElement) {
        document.webkitCancelFullScreen();
      } else if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else {
        document.documentElement.webkitRequestFullScreen();
      }
    };

    onMounted(() => {
      document.addEventListener("fullscreenchange", handleFullscreen);
      document.addEventListener("webkitfullscreenchange", handleFullscreen);
    });
    onUnmounted(() => {
      document.removeEventListener("fullscreenchange", handleFullscreen);
      document.removeEventListener("webkitfullscreenchange", handleFullscreen);
    });

    return {
      pageStore,
      fullScreen,

      // Icons
      icons: {
        mdiFullscreen,
        mdiFullscreenExit,
      },
    };
  },
};
</script>
