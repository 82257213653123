<template>
  <v-dialog
    v-model="pinia.viewDialogModel"
    :persistent="pinia.viewDialogLoading"
    max-width="650"
    class="elevation-0"
    scrollable
    overlay-opacity="0.6"
    attach=""
  >
    <v-card
      rounded="xl"
      flat
      :disabled="pinia.viewDialogLoading"
      :class="{
        'scrollable-card': !isScroll,
        'scrollable-text-card': !isScroll && $vuetify.breakpoint.mdAndDown,
        'scrollable-not-card': !isScroll && arrivedState.bottom,
      }"
    >
      <v-card-title class="grey">
        <h4 class="text-body-1 text-sm-h6 font-weight-bold">Tahsilat Detay</h4>

        <v-spacer />

        <v-btn
          v-if="$vuetify.breakpoint.smAndUp"
          rounded
          small
          depressed
          color="chip"
          class="text-capitalize"
          @click="pinia.viewDialogClose"
        >
          <v-icon left>{{ icons.mdiClose }}</v-icon>
          Kapat
        </v-btn>
        <v-icon v-else small @click="pinia.viewDialogClose">{{ icons.mdiClose }}</v-icon>
      </v-card-title>

      <v-divider />
      <v-card-text
        class="pa-0"
        ref="scrollableEl"
        :style="$vuetify.breakpoint.smAndUp ? 'height: 735px' : 'height: 100dvh'"
      >
        <v-list>
          <v-list-item class="px-3 px-md-6" two-line>
            <v-list-item-content>
              <v-list-item-subtitle>ID</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-chip color="chip" label class="d-flex justify-center text-caption">
                {{ pinia.viewDialogData.id }}
              </v-chip>
            </v-list-item-action>
          </v-list-item>
          <v-divider />

          <v-list-item class="px-3 px-md-6" two-line>
            <v-list-item-content>
              <v-list-item-subtitle>Durum</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-chip
                :color="pinia.statusList[pinia.viewDialogData.status].type"
                label
                class="d-flex justify-center text-caption"
                style="font-size: 11px !important; letter-spacing: 1.5px; color: azure"
              >
                {{ pinia.statusList[pinia.viewDialogData.status].text }}
              </v-chip>
            </v-list-item-action>
          </v-list-item>
          <v-divider />

          <v-list-item class="px-3 px-md-6">
            <v-list-item-content>
              <v-list-item-subtitle>Cari Unvan</v-list-item-subtitle>
              <v-list-item-title v-if="!$vuetify.breakpoint.smAndUp">{{
                pinia.viewDialogData.plasiyer.display_name
              }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action v-if="$vuetify.breakpoint.smAndUp">
              {{ pinia.viewDialogData.plasiyer.display_name }}
            </v-list-item-action>
          </v-list-item>
          <v-divider />

          <v-list-item class="px-3 px-md-6">
            <v-list-item-content>
              <v-list-item-subtitle>Güncelleme Tarihi</v-list-item-subtitle>
              <v-list-item-title v-if="!$vuetify.breakpoint.smAndUp">{{
                pinia.viewDialogData.updated_at | moment('DD.MM.YYYY - HH:mm')
              }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action v-if="$vuetify.breakpoint.smAndUp">
              <v-chip color="grey" class="d-flex justify-center" label>
                <div
                  class="d-flex align-center"
                  style="text-align: center; font-size: 11px !important; letter-spacing: 0.5px; color: azure"
                >
                  <v-icon color="" small class="mr-2">
                    {{ icons.mdiCalendarClock }}
                  </v-icon>
                  <div class="mr-1">
                    {{ pinia.viewDialogData.updated_at | moment('DD.MM.YYYY ') }}
                  </div>

                  <span> - {{ pinia.viewDialogData.updated_at | moment('HH:mm') }}</span>
                </div>
              </v-chip>
            </v-list-item-action>
          </v-list-item>
          <v-divider />

          <v-list-item class="px-3 px-md-6">
            <v-list-item-content>
              <v-list-item-subtitle>Kayıt Tarihi</v-list-item-subtitle>
              <v-list-item-title v-if="!$vuetify.breakpoint.smAndUp">{{
                pinia.viewDialogData.created_at | moment('DD.MM.YYYY - HH:mm')
              }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action v-if="$vuetify.breakpoint.smAndUp">
              <v-chip color="grey" class="d-flex justify-center" label>
                <div
                  class="d-flex align-center"
                  style="text-align: center; font-size: 11px !important; letter-spacing: 0.5px; color: azure"
                >
                  <v-icon color="" small class="mr-2">
                    {{ icons.mdiCalendarClock }}
                  </v-icon>
                  <div class="mr-1">
                    {{ pinia.viewDialogData.created_at | moment('DD.MM.YYYY ') }}
                  </div>

                  <span> - {{ pinia.viewDialogData.created_at | moment('HH:mm') }}</span>
                </div>
              </v-chip>
            </v-list-item-action>
          </v-list-item>
          <v-divider />

          <v-list-item class="px-3 px-md-6">
            <v-list-item-content>
              <v-list-item-subtitle>Çek Adet</v-list-item-subtitle>
              <v-list-item-title v-if="!$vuetify.breakpoint.smAndUp"
                >{{ pinia.viewDialogData.checksPic }} Adet</v-list-item-title
              >
            </v-list-item-content>
            <v-list-item-action v-if="$vuetify.breakpoint.smAndUp">
              <v-chip color="#9391a2" outlined class="d-flex justify-center" label>
                <div class="text-capitalize" style="text-align: center; font-size: 11px !important">
                  {{ pinia.viewDialogData.checksPic }} Adet
                </div>
              </v-chip>
            </v-list-item-action>
          </v-list-item>
          <v-divider />

          <v-list-item class="px-3 px-md-6">
            <v-list-item-content>
              <v-list-item-subtitle>Çek Toplam Tutar</v-list-item-subtitle>
              <v-list-item-title v-if="!$vuetify.breakpoint.smAndUp"
                >{{ pinia.viewDialogData.checksTotal | currency }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action v-if="$vuetify.breakpoint.smAndUp">
              {{ pinia.viewDialogData.checksTotal | currency }}
            </v-list-item-action>
          </v-list-item>
          <v-divider />

          <v-list-item three-line class="px-3 px-md-6">
            <v-list-item-content>
              <v-list-item-subtitle>Açıklama</v-list-item-subtitle>
              <v-list-item-title>{{ pinia.viewDialogData.description }} </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-list>
          <v-subheader class="px-3 px-md-6 text-subtitle-1 text-md-h6 white--text">Çekler</v-subheader>
          <template v-for="(item, index) in pinia.viewDialogData.checks">
            <v-divider :key="`divider-${index}`" />
            <v-list-item two-line :key="`item-${index}`">
              <v-list-item-avatar
                tile
                :height="$vuetify.breakpoint.smAndUp ? 75 : 50"
                :width="$vuetify.breakpoint.smAndUp ? 75 : 50"
                color="white"
                class="rounded-lg"
              >
                <a :href="$baseUrlAPI + '' + item.front_image_path" target="_blank">
                  <v-avatar tile :height="75" :width="75">
                    <v-img
                      v-if="item.front_image_path"
                      :src="$baseUrlAPI + '' + item.front_image_path"
                      class="rounded-lg"
                    />
                    <v-icon v-else size="30" color="primary"> {{ icons.mdiCameraOff }}</v-icon>
                  </v-avatar>
                </a>
              </v-list-item-avatar>
              <v-list-item-avatar
                tile
                :height="$vuetify.breakpoint.smAndUp ? 75 : 50"
                :width="$vuetify.breakpoint.smAndUp ? 75 : 50"
                color="white"
                class="rounded-lg"
              >
                <a :href="$baseUrlAPI + '' + item.back_image_path" target="_blank">
                  <v-avatar tile :height="75" :width="75">
                    <v-img
                      v-if="item.back_image_path"
                      :src="$baseUrlAPI + '' + item.back_image_path"
                      class="rounded-lg"
                    />
                    <v-icon v-else size="30" color="primary"> {{ icons.mdiCameraOff }}</v-icon>
                  </v-avatar>
                </a>
              </v-list-item-avatar>

              <v-list-item-content class="ml-3">
                <v-list-item-title> {{ item.total_price | currency }} </v-list-item-title>
                <v-list-item-subtitle> {{ item.expiry_date.slice(0, 10) }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>

        <div class="text-center"></div>
      </v-card-text>
      <v-card-actions class="py-3" v-if="userData.role !== 'PLSYR' && pinia.viewDialogData.status == 8">
        <v-btn
          color="secondary"
          @click="saldeptCollectionCompleteStatus(pinia.viewDialogData.id)"
          class="text-capitalize"
          block
          rounded
          x-large
        >
          Çek Teslim Alındı</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, onMounted } from 'vue-demi'

// Pinia
import { useCollectionStore } from '../store'

// Utils
import { useScroll, useResizeObserver } from '@vueuse/core'
import { postData } from '@utils'
import { useToast } from 'vue-toastification/composition'

// Icons
import { mdiClose, mdiTrashCan, mdiCalendarClock, mdiCameraOff, mdiDeleteOutline } from '@mdi/js'

export default {
  setup(props, { emit }) {
    // Pinia
    const pinia = useCollectionStore()

    const toast = useToast()
    const scrollableEl = ref(null)
    const userData = JSON.parse(localStorage.getItem('userData'))
    // Kaydırma çubuğu
    const { arrivedState } = useScroll(scrollableEl)
    const isScroll = ref(false)
    onMounted(() => {
      isScroll.value = scrollableEl.value?.scrollHeight === scrollableEl.value?.offsetHeight
    })

    // Ekran boyutu algılama
    useResizeObserver(scrollableEl, entries => {
      isScroll.value = scrollableEl.value?.scrollHeight === scrollableEl?.value.offsetHeight
    })

    const saldeptCollectionCompleteStatus = id => {
      postData({
        method: 'saldeptCollectionCompleteStatus',
        id,
      })
        .then(response => {
          // API dönüşündeki hatalara bakıyoruz
          if (response.error) {
            throw new Error(response.msg)
          }

          toast.success('İşlem Başarılı', {
            position: 'bottom-center',
          })
          emit('tableFetch')
        })
        .catch(e => {
          // API hataya girdi

          toast.error(e.message, {
            position: 'bottom-center',
          })
        })
        .finally(() => {
          // API işlemi bittikten sonra
        })
    }

    return {
      userData,

      pinia,
      scrollableEl,
      isScroll,
      arrivedState,
      saldeptCollectionCompleteStatus,
      // icons
      icons: {
        mdiClose,
        mdiTrashCan,
        mdiCalendarClock,
        mdiCameraOff,
        mdiDeleteOutline,
      },
    }
  },
}
</script>
