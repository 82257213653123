<template>
  <v-menu rounded="xl">
    <template v-slot:activator="{ attrs, on }">
      <v-btn
        :color="$vuetify.breakpoint.mdAndDown ? 'accent' : 'accent'"
        class="text-capitalize"
        :class="{ 'rounded-0 rounded-l-pill': $vuetify.breakpoint.mdAndDown }"
        rounded
        depressed
        v-bind="attrs"
        v-on="on"
      >
        <v-icon left>
          {{ icons.mdiCalendar }}
        </v-icon>
        {{ selectedRangeText }}
        <v-icon right v-if="!$vuetify.breakpoint.mdAndDown">
          {{ icons.mdiMenuDown }}
        </v-icon>
      </v-btn>
    </template>

    <v-list color="accent">
      <v-list-item-group v-model="selectedRange" color="primary" mandatory>
        <v-list-item v-for="(date, index) in dateRange" :key="index" link>
          <v-list-item-title class="white--text">{{ date.text }}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
import { ref, watch, computed } from "vue-demi";

// Pinia
import { usePageStore } from "@/stores/pageHeader";

// Plugin
import moment from "moment";

// Icons
import { mdiCalendar, mdiMenuDown } from "@mdi/js";
export default {
  setup() {
    const pageStore = usePageStore();
    const dateRange = ref([
      {
        text: "Bugün",
        value: "today",
        start: moment().format("YYYY-MM-DD"),
        end: moment().format("YYYY-MM-DD"),
      },
      {
        text: "Dün",
        value: "yesterday",
        start: moment().subtract(1, "days").format("YYYY-MM-DD"),
        end: moment().subtract(1, "days").format("YYYY-MM-DD"),
      },
      {
        text: "Bu Hafta",
        value: "thisWeek",
        start: moment().subtract(0, "weeks").startOf("week").format("YYYY-MM-DD"),
        end: moment().subtract(0, "weeks").endOf("week").format("YYYY-MM-DD"),
      },
      {
        text: "Geçen Hafta",
        value: "lastWeek",
        start: moment().subtract(1, "weeks").startOf("week").format("YYYY-MM-DD"),
        end: moment().subtract(1, "weeks").endOf("week").format("YYYY-MM-DD"),
      },
      {
        text: "Bu Ay",
        value: "thisMonth",
        start: moment().subtract(0, "month").startOf("month").format("YYYY-MM-DD"),
        end: moment().subtract(0, "month").endOf("month").format("YYYY-MM-DD"),
      },
      {
        text: "Geçen Ay",
        value: "lastMonth",
        start: moment().subtract(1, "month").startOf("month").format("YYYY-MM-DD"),
        end: moment().subtract(1, "month").endOf("month").format("YYYY-MM-DD"),
      },
      {
        text: "Son 3 Ay",
        value: "last3Months",
        start: moment().subtract(3, "month").endOf("month").format("YYYY-MM-DD"),
        end: moment().format("YYYY-MM-DD"),
      },
      {
        text: "Son 6 Ay",
        value: "last6Months",
        start: moment().subtract(6, "month").endOf("month").format("YYYY-MM-DD"),
        end: moment().format("YYYY-MM-DD"),
      },
      {
        text: "Bu Yıl",
        value: "thisYear",
        start: moment().year() + "-01-01",
        end: moment().format("YYYY-MM-DD"),
      },
      {
        text: "Geçen Yıl",
        value: "thisYear",
        start: moment().year() - 1 + "-01-01",
        end: moment().year() - 1 + "-12-31",
      },
    ]);

    const selectedRange = ref(3);
    if (pageStore.hasDateRangeAll) {
      dateRange.value.unshift({
        text: "Tümü",
        value: "all",
        start: null,
        end: null,
      });
      selectedRange.value = 0;
    }

    const selectedRangeText = computed(() => {
      if (dateRange.value) {
        return dateRange.value[selectedRange.value].text;
      } else {
        return "-";
      }
    });

    watch(selectedRange, (val) => {
      pageStore.dateRange = dateRange.value[val];
    });

    return {
      dateRange,
      selectedRange,
      selectedRangeText,
      icons: {
        mdiCalendar,
        mdiMenuDown,
      },
    };
  },
};
</script>
