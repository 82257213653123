import { computed, ref, getCurrentInstance } from "vue-demi";
import { defineStore } from "pinia";

// Plugin
import moment from "moment";

export const usePageStore = defineStore("pageHeader", () => {
  const vm = getCurrentInstance().proxy;

  const pageTitle = ref("");
  const pageIcon = ref("");

  const hasActions = ref(false);
  const hasFullScreen = ref(false);
  const hasDownload = ref(false);
  const hasDateRange = ref(false);
  const hasDateRangeAll = ref(false);

  const downloadData = ref([]);
  const downloadHeader = ref({});
  const isFullScreen = ref(false);

  
  const dateRange = ref({
    start: moment().subtract(1, "weeks").startOf("week").format("YYYY-MM-DD"),
    end: moment().subtract(1, "weeks").endOf("week").format("YYYY-MM-DD"),
    text: "Geçen Hafta",
    value: "lastWeek",
  });

  const tableHeight = computed(() => {
    let result = "80dvh";
    if (isFullScreen.value) {
      result = "calc(100dvh - 65px)";
    } else {
      if (vm.$vuetify.breakpoint.mdAndDown) {
        result = "calc(100dvh - 132px)";
      } else if (!vm.$vuetify.breakpoint.mdAndDown) {
        result = "calc(100dvh - 160px)";
      }
    }
    return result;
  });

  const pageSettingsClear = () => {
    pageTitle.value = "";
    pageIcon.value = "";

    hasActions.value = false;
    hasFullScreen.value = false;
    hasDownload.value = false;
    hasDateRange.value = false;
    hasDateRangeAll.value = false;

    downloadData.value = [];
    downloadHeader.value = {};
    isFullScreen.value = false;
    dateRange.value = {
      start: moment().subtract(1, "weeks").startOf("week").format("YYYY-MM-DD"),
      end: moment().subtract(1, "weeks").endOf("week").format("YYYY-MM-DD"),
      text: "Geçen Hafta",
      value: "lastWeek",
    };
  };

  return {
    pageTitle,
    pageIcon,
    tableHeight,

    hasDateRange,
    hasDateRangeAll,
    dateRange,

    hasActions,
    hasDownload,
    downloadData,
    downloadHeader,


    hasFullScreen,
    isFullScreen,

    pageSettingsClear,
  };
});
