<template>
  <v-menu bottom left rounded="xl">
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="me-2 text-capitalize" rounded  plain icon v-bind="attrs" v-on="on">
        <v-icon size="21" >{{ icons.mdiDownload }}</v-icon> 
      </v-btn>
    </template>

    <v-list nav color="chip" rounded width="120">
      <v-list-item @click="true">
        <v-list-item-icon class="mr-3">
          <v-icon color="white">{{ icons.mdiFileExcelBox }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            <download-excel
              :data="pageStore.downloadData"
              :fields="pageStore.downloadHeader"
              :name="pageStore.pageTitle"
            >
              <span class="text-caption white--text"> Excel </span>
            </download-excel>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="my-2"></v-divider>
      <v-list-item @click="pdfDownload()">
        <v-list-item-icon class="mr-3">
          <v-icon color="white">{{ icons.mdiFilePdfBox }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="text-caption white--text">PDF </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
// Pinia
import { usePageStore } from '@/stores/pageHeader'

// Plugin
import { jsPDF } from 'jspdf'
import callAddFont from '@/assets/font/OpenSans'
import autoTable from 'jspdf-autotable'

// Icons
import { mdiFileExcelBox, mdiFilePdfBox, mdiDownload } from '@mdi/js'

export default {
  setup() {
    const pageStore = usePageStore()

    // Pdf Download
    const pdfDownload = async () => {
      jsPDF.API.events.push(['addFonts', callAddFont])
      const doc = new jsPDF('l', 'mm', [297, 210])

      const pdfData = pageStore.downloadData.map(item => {
        let result = []
        Object.values(pageStore.downloadHeader).forEach(i => {
          result.push(item[i])
        })
        return result
      })

      autoTable(doc, {
        styles: { font: 'OpenSans-Medium', fontStyle: 'normal', fontSize: 8 },
        head: [Object.keys(pageStore.downloadHeader)],
        body: pdfData,
      })
      doc.save(pageStore.pageTitle)
    }

    return {
      pageStore,

      // Download
      pdfDownload,

      // Icons
      icons: {
        mdiDownload,
        mdiFileExcelBox,
        mdiFilePdfBox,
      },
    }
  },
}
</script>
